.gallery-container {
  background-color: rgba(250, 250, 250, 1.0);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  object-fit: contain;
}

.outside-margins-gallery {
  margin: 30px;
}


@media screen and (max-width: 960px) {
  .outside-margins-gallery{
    margin: 20px 0px;
  }
}
