.hero-container {
  background: url('../images/landscape.jpg') center center/cover no-repeat;
  height: 90vh;
  width: 100%;
  display: flex;
  margin-top: -80px;
  flex-direction: column;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  object-fit: contain;
}

.hero-container > h1 {
  text-align: start;
  color: rgb(240,240,240);
  font-size: 60px;
  margin-top: 300px;
  padding-left: 220px;
}

.hero-container > p {
  text-align: start;
  margin-top: 8px;
  padding-left: 220px;
  color: #fff;
  font-size: 28px;
  font-family: 'Open Sans', sans-serif;
}

.button-padding {
  margin-top: 2rem;
  padding-left: 205px;
}

@media screen and (max-width: 1550px) {
  .button-padding {
    padding-left: 110px;
  }

  .hero-container > h1 {
    padding-left: 125px;
  }

  .hero-container > p {
    padding-left: 125px;
  }

  .outside-margins {
    padding-right: 5px;
    padding-left: 5px;
  }
}

@media screen and (max-width: 1200px) {
  .button-padding {
    padding-left: 65px;
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: 300px;
    padding-left: 80px;
  }

  .hero-container > p {
    font-size: 25px;
    margin-top: 8px;
    padding-left: 80px;
  }
}

@media screen and (max-width: 960px) {
  .hero-container {
    margin-top: -60px;
  }

  .button-padding {
    padding-left: 15px;
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: 260px;
    padding-left: 30px;
  }

  .hero-container > p {
    padding-left: 30px;
  }

  .outside-margins {
    padding-right: 20px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 800px) {
  .button-padding {
    padding-left: 5px;
  }

  .hero-container > h1 {
    font-size: 50px;
    margin-top: 250px;
    padding-left: 20px;
  }

  .hero-container > p {
    font-size: 25px;
    margin-top: 8px;
    padding-left: 20px;
  }
}

@media screen and (max-width: 768px) {
  .hero-container > h1 {
    font-size: 45px;
    margin-top: 250px;
  }

  .hero-container > p {
    font-size: 20px;
  }

  .outside-margins {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media screen and (max-width: 670px) {
  .button-padding {
    padding-left: 5px;
  }

  .hero-container > h1 {
    font-size: 45px;
    margin-top: 250px;
    padding-left: 20px;
    text-align: start;
  }

  .hero-container > p {
    font-size: 20px;
    text-align: start;
    padding-left: 20px;
  }

  .outside-margins {
    padding-right: 25px;
    padding-left: 25px;
  }
}

@media screen and (max-width: 599px) {
  .button-padding {
    margin-left: -10px;
  }

  .hero-container {
    margin-top: -60px;
  }

  .hero-container > h1 {
    font-size: 42px;
    font-weight: 900;
    margin-top: 160px;
    padding-left: 10px;
    text-align: start;
  }

  .hero-container > p {
    font-size: 18px;
    text-align: start;
    padding-left: 10px;
  }

  .outside-margins {
    padding-right: 10px;
    padding-left: 10px;
  }

  .margin-top {
    margin-top: 5rem!important;
  }
}