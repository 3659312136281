.about-container {
  padding-bottom: 30px;
  background-color: rgba(250, 250, 250, 1.0);
  width: 100%;
  display: flex;
  flex-direction: column;
  object-fit: contain;
}

.about-container > h1 {
  color: #222222;
  font-size: 30px;
  margin-top: 40px;
  padding-left: 160px;
}

.about-margin-top {
  margin-top: 1rem;
}

.outside-margins-about {
  padding-left: 120px;
  padding-right: 120px;
}

@media screen and (max-width: 1279px) {
  .about-container > h1 {
    padding-left: 100px;
  }

  .outside-margins-about {
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media screen and (max-width: 960px) {
  .about-container > h1 {
    font-size: 30px;
    padding-left: 0px;
    text-align: center;
  }

  .outside-margins-about {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 800px) {
  .about-container > h1 {
    font-size: 30px;
  }

  .outside-margins-about {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .about-container > h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 599px) {
  .about-container > h1 {
    font-size: 25px;
  }

  .outside-margins-about {
    padding-left: 0px;
    padding-right: 0px;
  }
}