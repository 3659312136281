.contact-form-container {
  background: url('../images/landscape.jpg') center center/cover no-repeat;
  height: auto;
  width: 100%;
  display: flex;
  margin-top: -80px;
  padding: 240px 0;
  flex-direction: column;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
  object-fit: contain;
}

.contact-form-container > h1 {
  color: #222222;
  font-size: 30px;
  margin-top: 40px;
  padding-left: 160px;
}

.contact-form-margin-top {
  margin-top: 1rem;
}

.outside-margins-contact-form {
  padding-left: 120px;
  padding-right: 120px;
}

.number-text-contact a {
  text-align: center;
  font-size: 36px;
	text-decoration: none;
	color: rgb(255, 255, 255);
}

.number-text-contact a:hover {
	color: rgb(63,109,63);
}

.number-text-contact {
  padding-top: 16px;
}

@media screen and (max-width: 1279px) {
  .contact-form-container > h1 {
    padding-left: 100px;
  }

  .outside-margins-contact-form {
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media screen and (max-width: 960px) {
  .contact-form-container {
    margin-top: -60px;
    padding: 60px 0;
  }

  .contact-form-container > h1 {
    font-size: 30px;
    padding-left: 0px;
    text-align: center;
  }

  .outside-margins-contact-form {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 800px) {
  .contact-form-container > h1 {
    font-size: 30px;
  }

  .outside-margins-contact-form {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .contact-form-container > h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 599px) {
  .contact-form-container > h1 {
    font-size: 25px;
  }

  .outside-margins-contact-form {
    padding-left: 0px;
    padding-right: 0px;
  }
}