.call-container {
  padding-bottom: 30px;
  background-color: rgba(250, 250, 250, 1.0);
  background: url('../images/grass.jpg') center center/cover no-repeat;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 50vh;
  padding: 0 120px;
}

.call-center {
  padding-top: 60px;
  text-align: center;
}

.call-center > h2 {
  padding-top: 8px;
  font-size: 32px;
  color: rgba(240, 240, 240, 1.0);
  text-align: center;
}

.number-text a {
  text-align: center;
  font-size: 36px;
	text-decoration: none;
	color: rgb(255, 255, 255);
}

.number-text a:hover {
	color: rgb(63,109,63);
}

.number-text {
  padding-top: 16px;
}


@media screen and (max-width: 960px) {
  .call-container {
    padding: 0 20px;
  }

  .call-center {
    padding-top: 50px;
  }

  .call-center > h1 {
    color: rgba(240, 240, 240, 1.0);
    text-align: center;
  }
  
  .call-center > h2 {
    font-size: 28px;
    color: rgba(240, 240, 240, 1.0);
    text-align: center;
  }
}

@media screen and (max-width: 600px) {
  .call-container {
    padding: 0 20px;
  }

  .call-center {
    padding-top: 15px;
  }

  .call-center > h2 {
    font-size: 22px;
    color: rgba(240, 240, 240, 1.0);
    text-align: center;
  }
}

@media screen and (max-width: 350px) {
  .call-container {
    padding: 0 20px;
  }

  .call-center {
    padding-top: 10px;
  }

  .call-center > h2 {
    font-size: 16px;
    color: rgba(240, 240, 240, 1.0);
    text-align: center;
  }

  .number-text > a {
    font-size: 18px;
  }
}