.infobar {
  background-color: rgb(28, 28, 28);
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: .9rem;
  top: 0;
  z-index: 0;
}

.infobar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  max-width: 1500px;
}

.infobar-logo {
  color: rgb(225,225,225);
  justify-self: start;
  margin-left: 20px;
  cursor: pointer;
  text-decoration: none;
  font-size: .9rem;
  display: flex;
  align-items: center;
}

.info-menu {
  display: grid;
  grid-template-columns: repeat(4, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: .1rem;
}

.brands {
  font-family: "Font Awesome 5 Brands";
}

.pad-right {
  padding-right: 10px;
}

.info-icons {
  color: rgb(63, 109, 63);
  padding-right: 8px;
  padding-top: 2px;
  font-size: .9rem;
}

.info-icons-white {
  color: rgb(225, 225, 225);
  padding-top: 10px;
  padding-right: 4px;
  font-size: 1.4rem;
}

.info-item {
  height: 40px;
}

.info-links {
  color: rgb(225,225,225);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.info-links:hover {
  border-bottom: 3px solid rgb(27, 27, 27);
  transition: all 0.2s ease-out;
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .infobar {
    height: 30px;
  }

  .info-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 80px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .infobar-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(40%, 40%);
  }
}