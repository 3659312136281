.service-container {
  background-color: rgba(250, 250, 250, 1.0);
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  object-fit: contain;
  padding:80px 0;
}

.service-container > h1 {
  color: #222222;
  font-size: 30px;
  padding-left: 160px;
}

.link {
  color: rgb(63,109,63);
  font-size: 22px;
  text-decoration: none;
  padding-left: 160px;
  padding-bottom: 32px;
}

.link-chevron {
  color: rgb(63,109,63);
  font-size: 12px;
  padding-left: 8px;
}

.image {
  height: 220px;
  width: 280px;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.4);
}

.margin-top-service {
  margin-top: 20px;
}

.outside-margins-service {
  padding-left: 80px;
  padding-right: 80px;
}


@media screen and (max-width: 1279px) {

  .service-container > h1 {
    padding-left: 100px;
  }

  .link {
    padding-left: 100px;
  }

  .outside-margins-service {
    padding-left: 75px;
    padding-right: 75px;
  }
}

@media screen and (max-width: 960px) {

  .service-container > h1 {
    font-size: 30px;
    padding-left: 0px;
    text-align: center;
  }

  .link {
    padding-left: 0px;
    text-align: center;
  }

  .outside-margins-service {
    padding-left: 50px;
    padding-right: 50px;
  }
}

@media screen and (max-width: 800px) {
  .service-container > h1 {
    font-size: 30px;
  }

  .outside-margins-service {
    padding-left: 20px;
    padding-right: 20px;
  }
}

@media screen and (max-width: 768px) {
  .service-container > h1 {
    font-size: 30px;
  }
}

@media screen and (max-width: 599px) {

  .service-container > h1 {
    font-size: 25px;
  }

  .link {
    font-size: 18px;
    padding-top: 8px;
  }

  .outside-margins-service {
    padding-left: 0px;
    padding-right: 0px;
  }
}