.footer-wrapper {
	background-color: rgb(54, 58, 53);
	padding: 24px 0;
}

.footer-container {
	width: 100%;
	display: flex;
	justify-content: center;
}

.grid {
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 60px;
  margin: 24px 260px;
}

.grid-item {
	padding: 0 10px;
}

.header {
	color: rgb(220,220,220);
	letter-spacing: .7px;
	font-size: 20px;
	margin-bottom: 8px;
}

.subtitle {
	color: rgb(200,200,200);
	font-size: 16px;
}

.row-grid {
	display: grid;
	grid-template-rows: 1fr;
}

.row-grid a {
	text-decoration: none;
	color: rgb(200,200,200);
}

.row-grid a:hover {
	color: rgb(63,109,63);
}

.row-grid-item {
	padding: 4px 0;
}

.row-grid-item-2 {
	color: rgb(200,200,200);
	padding: 1px 0;
}

.hours {
	color: rgb(200,200,200);
	padding: 4px 0;
	font-size: 18px;
}

.subhours {
	color: rgb(200,200,200);
	padding: 0 0;
	font-size: 16px;
}

.copyright {
	padding-top: 24px;
	font-size: 12px;
	text-align: center;
	color: rgb(215,215,215);
}

.copyright a {
	text-decoration: none;
	color: rgb(215,215,215);
}

@media screen and (max-width: 960px) {
	.grid {
		grid-template-columns: 1fr;
		text-align: left;
		margin: 24px 24px;
	}
}