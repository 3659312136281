.navbar {
  background-image: -webkit-linear-gradient(160deg, rgb(63, 109, 63) 65%, rgb(250,250,250) 65%);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.4rem;
  position: sticky;
  top: 0;
  z-index: 999;
}

.navbar-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80px;
  max-width: 100vw;
}

.navbar-logo {
  color: #222222;
  justify-self: start;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 120px;
  text-decoration: none;
  font-size: 2rem;
  display: flex;
  align-items: center;
}

.fa-typo3 {
  margin-left: 0.5rem;
  font-size: 1.8rem;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  grid-gap: 10px;
  list-style: none;
  text-align: center;
  width: 60vw;
  justify-content: end;
  margin-right: 2rem;
}

.nav-item {
  height: 80px;
}

.nav-links {
  color: rgb(225,225,225);
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0.5rem 1rem;
  height: 100%;
}

.nav-links:hover {
  border-bottom: 3px solid rgb(225,225,225);
  transition: all 0.2s ease-out;
}

.fa-bars {
  color: #444444;
}

.fa-chevron-down {
  color: rgb(240, 240, 240);
  padding-left: 8px;
  padding-top: 4px;
  font-size: .7rem;
}

.nav-links-mobile {
  display: none;
}

.menu-icon {
  display: none;
}

@media screen and (max-width: 1750px) {
  .navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 1;
    z-index: 999;
  }

  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 100vw;
  }

  .navbar-logo {
    color: #222222;
    justify-self: start;
    cursor: pointer;
    padding-left: 1px;
    padding-right: 200px;
    text-decoration: none;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
  }

  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }

  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 50vw;
    justify-content: end;
    margin-right: .1rem;
  }

  .nav-item {
    height: 80px;
  }

  .nav-links {
    color: rgb(225,225,225);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }

  .nav-links:hover {
    border-bottom: 3px solid rgb(225,225,225);
    transition: all 0.2s ease-out;
  }
}

@media screen and (max-width: 1440px) {
  .navbar {
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
    position: sticky;
    top: 1;
    z-index: 999;
  }

  .navbar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 80px;
    max-width: 100vw;
  }

  .navbar-logo {
    color: #222222;
    justify-self: start;
    cursor: pointer;
    padding-left: 1px;
    padding-right: 200px;
    text-decoration: none;
    font-size: 1.8rem;
    display: flex;
    align-items: center;
  }

  .fa-typo3 {
    margin-left: 0.5rem;
    font-size: 1.8rem;
  }

  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 50vw;
    justify-content: end;
    margin-right: .1rem;
  }

  .nav-item {
    height: 80px;
  }

  .nav-links {
    color: rgb(225,225,225);
    display: flex;
    align-items: center;
    text-decoration: none;
    padding: 0.5rem 1rem;
    height: 100%;
  }

  .nav-links:hover {
    border-bottom: 3px solid rgb(225,225,225);
    transition: all 0.2s ease-out;
  }
}

@media screen and (max-width: 960px) {
  .NavbarItems {
    position: relative;
  }

  .nav-item {
    height: 60px;
  }

  .logo-text {
    padding-left: 10px;
    font-size: 1.5rem;
    white-space: nowrap;
  }
  .logo-img {
    height: 40px;
  }

  .navbar {
    height: 60px;
    background-image: -webkit-linear-gradient(0deg, rgb(250,250,250) 65%, rgb(250,250,250) 65%);
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 90vh;
    position: absolute;
    top: 60px;
    left: -100%;
    opacity: 1;
    transition: all 0.5s ease;
  }

  .nav-menu.active {
    background: rgb(250,250,250);
    left: 0;
    opacity: 1;
    transition: all 0.5s ease;
    z-index: 1;
  }

  .nav-links {
    color: #444444;
    text-align: center;
    padding: 1rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    height: 60px;
    background-color: rgb(255,255,255);
    color: #242424;
    border-radius: 0;
  }

  .navbar-logo {
    position: absolute;
    top: 0;
    left: 0;
    width: 300px;
    transform: translate(1%, 25%);
  }

  .menu-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 40%);
    font-size: 1.6rem;
    cursor: pointer;
  }

  .fa-times {
    color: #444444;
    font-size: 2rem;
  }

  .fa-chevron-down {
    color: #444444;
  }

  .nav-links-mobile {
    display: block;
    text-align: center;
    margin: 2rem auto;
    border-radius: 4px;
    width: 80%;
    text-decoration: none;
    font-size: 1.5rem;
    background-color: transparent;
    color: #555555;
    padding: 14px 20px;
    border: 1px solid #fff;
    transition: all 0.3s ease-out;
  }

  .nav-links-mobile:hover {
    background-color: #ffffff;
    color: #242424;
    transition: 250ms;
  }
}