.servicepage-container {
  background-color: rgb(250,250,250);
  text-align: center;
  padding: 24px 24px;
}

.outside-margins-servicepage {
  padding: 24px 240px;
}

@media screen and (max-width: 1279px) {
  .outside-margins-servicepage {
    padding: 24px 60px;
  }
}

@media screen and (max-width: 960px) {
  .outside-margins-servicepage {
    padding: 24px 0;
  }
}